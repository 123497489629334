import React,{useRef} from "react";

import EarthDayMap from "../../assets/textures/8k_earth_daymap.jpeg";
import EarthNormalMap from "../../assets/textures/8k_earth_normal_map.jpeg";
import EarthSpecularMap from "../../assets/textures/8k_earth_specular_map.jpeg";
import EarthCloudMap from "../../assets/textures/8k_earth_clouds.jpg";
import { useFrame, useLoader } from "@react-three/fiber";
import {OrbitControls,Stars} from "@react-three/drei";
import { TextureLoader } from "three";
import * as THREE from "three";

export function Earth(props){
    const [colorMap, normalMap, specularMap, cloudMap]= useLoader(TextureLoader, [EarthDayMap,EarthNormalMap,EarthSpecularMap,EarthCloudMap]);
    const earthRef= useRef();
    const cloudRef= useRef();
    var time='';
    useFrame(({clock})=> {
        const elapsedTime= clock.getElapsedTime();
        earthRef.current.rotation.y = elapsedTime / 6;
        cloudRef.current.rotation.y= elapsedTime / 6;
        if(earthRef.current.position.z<2){
            time=clock.getElapsedTime();
            earthRef.current.position.z=earthRef.current.position.z+0.01;
            cloudRef.current.position.z=cloudRef.current.position.z+0.01;
        }
        else if(clock.getElapsedTime()-time>2){
            earthRef.current.position.z=earthRef.current.position.z+0.01;
            cloudRef.current.position.z=cloudRef.current.position.z+0.01;
        }
    });
    return <>
       
        <pointLight color="white" position={[2,0,5]} intensity={1.2}/>
        <Stars radius={300} depth={60} count={10000} factor={7} saturation={0} fade={true}/>
        <mesh ref= {cloudRef} position={[0,-0.5,-10]}>
            <sphereGeometry args= {[1.005,32,32]}/>
            <meshPhongMaterial map={cloudMap} opacity={0.4} depthWrite={true} transparent={true} side={THREE.DoubleSide}/>
        </mesh>
        <mesh ref={earthRef} position= {[0,-0.5,-10]}>
             <sphereGeometry args={[1, 32, 32]}/>
             <meshPhongMaterial specularMap={specularMap}/>
             <meshStandardMaterial map= {colorMap} normalMap={normalMap} metalness={0.4} roughness={0.7}/>
             <OrbitControls enableZoom={true} enablePan={true} enableRotate={true} zoomSpeed={0.5} panSpeed={0.5} rotateSpeed={0.4}/>
        </mesh>
    </>
}
import './App.css';
import { useState, useEffect } from 'react'
import styled from "styled-components";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { Earth } from '../components/earth';
import { TopSectionSecond } from '../components/text/header';
import { FaInstagram } from 'react-icons/fa';

const CanvasContainer = styled.div`
 width: 100%;
 height: 100%
 `;
function SecondPage({ changeScene }) {

    return (
        <TopSectionSecond changeScene={changeScene}/>
    );
        /*
        <div>
            <div>Essayas Kassa</div>
            <div>

            </div>
            <div className="social-icons">
                <a href='https://www.instagram.com/yourusername'>
                    <FaInstagram color="#fff" size={24} />
                </a>
                <a href='https://www.facebook.com/yourusername'>
                    <FaInstagram color="#fff" size={24} />
                </a>
                <a href='https://twitter.com/yourusername'>
                    <FaInstagram color="#fff" size={24} />
                </a>
            </div>

        </div>

    );
    /*
    const message="Hello this is essayas talking to you";
    const [typedMessage,setTypedMessage]= useState('');
    useEffect(()=>{
      setTimeout(()=>{
        setTypedMessage(message.slice(0,typedMessage.length+1));
        return () => clearTimeout();
      },500)
    },[typedMessage]);
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <div class="blinking-cursor">
            {typedMessage} 
          </div>
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    );
    */
}

export default SecondPage;

import logo from './logo.svg';
import './App.css';
import {useState,useEffect} from 'react'
import styled from "styled-components";
import {Canvas} from "@react-three/fiber";
import {Suspense} from "react";
import { Earth } from './components/earth';
import { TopSection } from './components/text/header';
import FirstPage from "./pages/first";
import SecondPage from "./pages/second";

const CanvasContainer = styled.div`
 width: 100%;
 height: 100%
 `;
function App() {
  const [currentScene, setCurrentScene] = useState("firstPage");

  const changeScene = (newScene) => {
    setCurrentScene(newScene)
  }

  return (
    <>{currentScene === "firstPage" ? <FirstPage changeScene={changeScene}/> : <SecondPage changeScene={changeScene}/>}</>

  );
}

export default App;

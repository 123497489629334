import './App.css';
import {useState,useEffect} from 'react'
import styled from "styled-components";
import {Canvas} from "@react-three/fiber";
import {Suspense} from "react";
import { Earth } from '../components/earth';
import { TopSection } from '../components/text/header';


const CanvasContainer = styled.div`
 width: 100%;
 height: 100%
 `;
function FirstPage({changeScene}) {
  
  return <CanvasContainer>
  <TopSection changeScene={changeScene}/>
  <Canvas>
    <Suspense fallback={null}>
        <Earth/>
    </Suspense>
  </Canvas>
  </CanvasContainer>;
  /*
  const message="Hello this is essayas talking to you";
  const [typedMessage,setTypedMessage]= useState('');
  useEffect(()=>{
    setTimeout(()=>{
      setTypedMessage(message.slice(0,typedMessage.length+1));
      return () => clearTimeout();
    },500)
  },[typedMessage]);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div class="blinking-cursor">
          {typedMessage} 
        </div>
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
  */
}

export default FirstPage;

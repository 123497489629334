import { useEffect, useState } from "react";
import logo from '../../../logo.svg';
import '../../../App.css';
import styled, { css, keyframes }from "styled-components";
import axios from 'axios';
import { FaInstagram,FaLinkedin,FaGithub } from 'react-icons/fa';


const TopSectionContainer = styled.div`
    position: absolute; 
    width: 100%; 
    height: 100%;
    top: 50px;
    left: 0;
    display: flex; 
    flex-direction: column;
    align-items: center;
`;

const Slogan = styled.h4`
    margin: 0; 
    color: 	#0e6b0e;
    font-family: monospace;
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 1em;
    text-shadow: 0 0 10px green, 0 0 20px green, 0 0 30px green;
    white-space: pre-wrap;

    @media (max-width: 600px) {
    font-size: 1.2rem;

    }
`;

const ContentHeader = styled.h1`
    margin: 0; 
    color: 	#0e6b0e;
    font-family: monospace;
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 1em;
    text-shadow: 0 0 10px green, 0 0 20px green, 0 0 30px green;
    white-space: pre-wrap;
    @media (max-width: 600px) {
    font-size: 1.2rem;
    }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 120px;
`;

const IconLink = styled.a`
  color: black;
  :hover {
    color: blue;
  }
`;

const StyledInstagram = styled(FaInstagram)`
  color:#0e6b0e;
  font-size: 54px;
  margin-right: 16px;
  margin-top: 26px;
  :hover {
    color: orange;
  }
`
const StyledLinkedin = styled(FaLinkedin)`
  color:#0e6b0e;
  font-size: 54px;
  margin-right: 16px;
  margin-top: 26px;
  :hover {
    color: orange;
  }
`

const StyledGithub = styled(FaGithub)`
  color:#0e6b0e;
  font-size: 54px;
  margin-right: 16px;
  margin-top: 26px;
  :hover {
    color: orange;
  }
`
const blink = keyframes`
    50%{
      opacity: 0;
    }
`;
const BlinkingCursor = styled.div`
    &:after{
    content: "|";
    animation: ${blink} 1s step-start infinite;
    }

`;


export function TopSection({changeScene}) {
    //var message = "Trying to access server from ";
    var [message,setMessage] = useState('')
  //creating function to load ip address from the API
  const getData = async () => {
    if(message===''){
    message+="Trying to access server from ";
    const res = await axios.get('https://geolocation-db.com/json/');
    message+="\r\nTriangulating location .....";
    message+="\nCountry : "+res.data.country_name;
    message+="\nCity : "+res.data.city;
    message+="\nProvince : "+res.data.state;
    message+="\nGathering user information..."
    message+="\nAccess Granted!"
    setMessage(message);
    }
  }
  const change = async () => {
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      // waits for 1000ms
      await sleep(3500);
    changeScene("scene2");
  }
    const [typedMessage, setTypedMessage] = useState('');
    const [time, setTime]=useState('');
    getData();
    useEffect(() => {
        setTimeout(() => {
            if(typedMessage[typedMessage.length-1]!=='!'){
            setTypedMessage(message.slice(0, typedMessage.length + 1));
            }
            return () => clearTimeout();
            
        }, 50)
        if(typedMessage[typedMessage.length-1]==='!'){
            change();
        }
        
    }, [typedMessage]);

    return <TopSectionContainer>
        <Slogan>
        <BlinkingCursor>{typedMessage}</BlinkingCursor>
        </Slogan>
    </TopSectionContainer>
    
}





export function TopSectionSecond({changeScene}) {
    var message = "As a holder of a BSc. Honours degree in Computer Science with a minor in Mathematics,I am driven by the challenge of solving complex problems through algorithmic means. My passion lies in utilizing artificial intelligence to tackle real-world issues, particularly in the realm of back-end systems and Internet of Things (IoT) technologies. In my leisure time, I indulge in a variety of sports, with a particular emphasis on competitive programming. Basketball holds a special place in my heart as it was my first love and I even had the opportunity to play professionally.";

    return (
        
    <TopSectionContainer>
      
        <ContentHeader>Essayas Kassa</ContentHeader>
        <Slogan style={{ textAlign: "left",margin: "50px" }}>
        <BlinkingCursor>{message}</BlinkingCursor>
        </Slogan>
        <IconContainer>
            <IconLink href="#" target="_blank" rel="noopener noreferrer">
                <StyledLinkedin/>
            </IconLink>
            <IconLink href="https://www.linkedin.com/in/essayas-kassa/"  target="_blank" rel="noopener noreferrer">
                <StyledGithub/>
            </IconLink>
            <IconLink href="#" target="_blank" rel="noopener noreferrer">
                <StyledInstagram/>
            </IconLink>
        </IconContainer>
    </TopSectionContainer>);
    
}